import React, { useState } from "react"
import { Formik, Form, Field } from "formik"
import * as R from "ramda"
import Button from "@material-ui/core/Button"
import LinearProgress from "@material-ui/core/LinearProgress"
import { TextField, CheckboxWithLabel } from "formik-material-ui"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import submitGenericLead from "../../api/submitGenericLead"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: "6px",
    backgroundColor: colors.grey["-"],
    padding: "2rem 1.5rem",
    transition: "height 2s linear",
    marginBottom: '30px'
  },
  formHeader: {
    textTransform: "uppercase",
    marginBottom: "2rem",
    fontSize: "1.3rem",
  },
  formIntro: {
    marginBottom: "1rem",
  },
  formField: {
    marginBottom: "0.25rem",
  },
  textField: {
    marginTop: "0",
    width: "100%",
    /* The margin bootom stops validation messages from pushing content down 
    This is a little bit hacky */
    "& .MuiInputBase-root": {
      marginBottom: "0.5rem",
    },
    "& .MuiInputBase-root.Mui-error": {
      marginBottom: "0",
    },
  },
  submitButton: {
    width: "100%",
    maxWidth: '300px'
  },
}))

const GenericLeadForm = ({
  text,
  thanksTitle,
  thanksText,
  defaultMessage,
  meta,
}) => {
  const [submitted, setSubmitted] = useState(false)

  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          company: "",
          email: "",
          phone: "",
          message: defaultMessage || "",
          marketing: false,
        }}
        validate={values => {
          const errors = {}
          if (!values.email) {
            errors.email = "Pakollinen tieto"
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = "Pakollinen tieto"
          }
          if (!values.phone) {
            errors.phone = "Pakollinen tieto"
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          /* Gather extra paghe data */

          submitGenericLead({
            form: values,
            meta,
          })
          setSubmitting(false)
          setSubmitted(true)

          // Datalayer push on form submit succeed
          const dataLayerObject = {
            'event':'formSubmit',
            'formName':'genericLeadForm',
          };

          // Push datalayer object
          if (typeof window !== 'undefined') {
            if(window.dataLayer){
              if(window.dataLayer.length > 0 && !R.equals(window.dataLayer[window.dataLayer.length - 1], dataLayerObject)){
                // eslint-disable-next-line no-undef
                window.dataLayer.push(dataLayerObject);
              }else if(window.dataLayer.length === 0){
                // eslint-disable-next-line no-undef
                window.dataLayer.push(dataLayerObject);
              }
            }else{
              window.dataLayer = [];
              // eslint-disable-next-line no-undef
              window.dataLayer.push(dataLayerObject);
            }
          }

        }}
      >
        {({ submitForm, isSubmitting }) =>
          submitted ? (
            <Box>
              <Typography
                variant="h6"
                component="p"
                className={classes.formHeader}
              >
                {thanksTitle}
              </Typography>
              <Typography
                variant="body2"
                component="p"
                className={classes.formIntro}
              >
                {thanksText}
              </Typography>
            </Box>
          ) : (
            <Form>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={3}
              >
                
              
                <Grid item xs={12}>
                  <Typography
                  variant="body2"
                  component="p"
                  className={classes.formIntro}
                >
                    {text}
                  </Typography>
                </Grid>
              
                <Grid item xs={12} sm={4}>
                  <Box className={classes.formField}>
                    <label>
                      Etunimi *
                      <Field
                        component={TextField}
                        name="firstname"
                        type="text"
                        required
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                      />
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box className={classes.formField}>
                      <label>
                        Sukunimi *
                        <Field
                          component={TextField}
                          name="lastname"
                          type="text"
                          required
                          className={classes.textField}
                          margin="normal"
                          variant="outlined"
                        />
                      </label>
                  </Box>
                </Grid>  
                <Grid item xs={12} sm={4}>
                  <Box className={classes.formField}>
                    <label>
                      Puhelinnumero *
                      <Field
                        component={TextField}
                        name="phone"
                        type="tel"
                        required
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                      />
                    </label>
                  </Box>
                </Grid>  
                <Grid item xs={12} sm={6}>
                  <Box className={classes.formField}>
                    <label className={classes.formField}>
                      Sähköposti *
                      <Field
                        component={TextField}
                        type="email"
                        name="email"
                        autoComplete="email"
                        required
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                      />
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={classes.formField}>
                    <label>
                      Yritys
                      <Field
                        component={TextField}
                        name="company"
                        type="text"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                      />
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.formField}>
                    <label className={classes.formField}>
                      Viestisi
                      <Field
                        component={TextField}
                        type="text"
                        name="message"
                        multiline
                        rows={4}
                        rowsMax={4}
                        className={`${classes.textField} ${classes.textFieldMultiline}`}
                        margin="normal"
                        variant="outlined"
                      />
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.formField}>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="marketing"
                      Label={{ label: "Hyväksyn tietojeni käsittelyn" }}
                      size={"small"}
                      //checkedIcon={<CheckBoxIcon fontSize="small" />}
                      color="primary"
                      disableRipple
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  {isSubmitting && <LinearProgress />}
                  <br />
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.submitButton}
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Lähetä viesti
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )
        }
      </Formik>
    </Box>
  )
}

export default GenericLeadForm
