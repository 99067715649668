import React, { useContext} from "react"
import { graphql } from "gatsby"

import * as R from "ramda"

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

import SearchContext from "../contexts/SearchContext"

import Layout from "../components/layout";
import SEO from "../components/SEO";
import Hero from "../components/page/Hero";
import GenericLeadForm from "../components/forms/GenericLeadForm";
import FeaturedPages from "../components/page/FeaturedPages";

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: "2.5rem",
  },
  lastSection: {
    marginTop: "4.5rem",
    marginBottom: "0",
  },
}))

const ContactPage = ({ data }) => {
  
  // Pad the featured articles with latest posts (if less than 5)
  const featuredArticles = R.path(
    ["contentfulEtusivu", "featuredArticles"],
    data
  )
    ? R.path(["contentfulEtusivu", "featuredArticles"], data)
    : []
  const featuredArticleIDs =
    featuredArticles.length > 0 ? featuredArticles.map(a => a.id) : []
  const articles = featuredArticles.concat(
    R.path(["extraArticles", "nodes"], data)
      .filter(article => !featuredArticleIDs.includes(article.id))
      .sort(() => 0.5 - Math.random())
      .slice(0, 5 - featuredArticles.length)
  )

  const classes = useStyles()

  const searchContext = useContext(SearchContext)
  searchContext.setHeroLoaded(true);

  return (
    <Layout>
      <SEO title="Toimitilahaku" />
      <Hero
        headline="Ota yhteyttä"
        backgroundImageFluid={R.path(
          ["contentfulEtusivu", "pageHeadingImage", "gatsbyImageData"],
          data
        )}
      >
        <GenericLeadForm
          text="Kirjoita yhteystietosi ja toiveesi oheiseen lomakkeeseen, niin ammattilaisemme auttavat sinua löytämään yrityksellesi sopivan tilaratkaisun."
          thanksTitle="Kiitos yhteydenotostasi!"
          thanksText="Olemme sinuun yhteydessä. Sillä välin voit lukea toimitilan valintaan liittyviä vinkkejämme."
          defaultMessage="Mitkä ovat toiveesi ja tarpeesi toimitilan sijaintiin, tilan kokoon, henkilömäärään tai palveluihin liittyen?"
        ></GenericLeadForm>
      </Hero>

      {/* Tarinat Latest */}
      {articles && (
        <Box className={classes.sectionBottom}>
          <FeaturedPages
            title="TARINAT"
            linkTo="/tarinat"
            data={R.slice(2, 5, articles)}
          ></FeaturedPages>
        </Box>
      )}
    </Layout>
  )
}

export default ContactPage

// This is temporary taking the data from the old format & it will just return all the premises data. It also just takes the first 3 results, in the final data we will makes ure we get the most relevent results
// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query contactPageQuery {
    contentfulEtusivu(activeVersion: { eq: "Kyllä" }) {
      pageHeadingTitle
      pageHeadingImage {
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 1200, 
          quality: 30,
        )
      }
      searchResultHeading
      newsecInfoHeading
      newsecInfoText {
        newsecInfoText
      }
      newsecInfoBalls {
        metric
        description
        icon {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 150, 
            quality: 90,
          )
        }
      }
      newsecAboutHeading
      newsecAboutText {
        newsecAboutText
      }
      newsecAboutBackgroundImage {
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 900, 
          quality: 90,
        )
      }
      newsecAboutLink

      featuredArticles {
        id
        slug
        title
        heading
        headingImage {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 660, 
            quality: 90,
          )
        }
        leadText {
          leadText
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    extraArticles: allContentfulArticle(
      filter: { publishedDate: { ne: null }, slug: { regex: "/^[^_]/" } }
      sort: { fields: publishedDate, order: DESC }
      limit: 5
    ) {
      nodes {
        id
        slug
        title
        heading
        headingImage {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 660, 
            quality: 90,
          )
        }
        leadText {
          leadText
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
    }
    
    
  }
`
